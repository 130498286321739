// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const zehnJahresZinshaus = {
  name: "10-Jahres-Zinshaus",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/7/f/csm_10jahres_haus_9a6d99fac7.jpg",
  url: "https://www.immoselection.at/investitionsarten/10-jahres-zinshaus/",
  tagline:
    "Hier erwerben Sie einen Anteil an einem neu gebauten oder frisch sanierten, vermieteten Zinshaus",
  facts: [
    "Mindestinvestment: € 25.000",
    "Veranlagungszeitraum: mittelfristig - über 10 Jahre",
    "Rendite: lfd. 3% + Wertsteigerung",
    "Erwartete Gesamtrendite: 5 - 7%",
  ],
  rules: [
    // 1. Investionsziel
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die Eigennutzung ist nur unter Bezahlung einer fremdüblichen Miete möglich, da sonst die restlichen Miteigentümer benachteiligt sind.",
      },
    },
    {
      wenn: { spaetereEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die Eigennutzung ist nur unter Bezahlung einer fremdüblichen Miete möglich, da sonst die restlichen Miteigentümer benachteiligt sind.",
      },
    },

    // 3. Finanzierung
    {
      wenn: { finanzierung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die Hebelung des Investments durch eine Finanzierung ist nicht möglich, da die hypothekarische Besicherung auf den Anteilen ausgeschlossen ist.",
      },
    },

    // 4. Laufende Eigenmittel

    {
      wenn: { eigenmittelersatz: true, laufendeEigenmittel: false },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },

    // 5. Kapitalbindung: Nur 10 Jahre einschließen.
    {
      wenn: { kapitalbindung: "-5Jahre" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die kurze Kapitalbindung ist fraglich, ob die Wertentwicklung die angefallenen Nebenkosten beim Ankauf bereits übersteigt.",
      },
    },
    {
      wenn: { kapitalbindung: "11-25Jahre" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Veranlagungshorizont ist größer als die Laufzeit der Veranlagung.",
      },
    },
    {
      wenn: { kapitalbindung: "25Jahre-" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Veranlagungshorizont ist größer als die Laufzeit der Veranlagung.",
      },
    },
  ],
};

export default zehnJahresZinshaus;
