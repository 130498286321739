/** @jsxImportSource theme-ui */
// @ts-check
// import React from "react";
import { useLocation } from "react-router-dom";
import getAnswersFromLocation from "../components/getAnswersFromLocation";
import ProductSearchPage from "../components/ProductSearchPage/ProductSearchPage";
// import rankProducts from "../rankProducts";
import { AnswersProvider } from "../components/ProductSearchPage/useAnswers";
// import getSummary from "../components/getSummary";
// import groupResults from "../groupResults";
import products from "../products";

export default function SearchPage() {
  /** @type {{ state: { answers?: import("../rankProducts").Answers} }} */
  const location = useLocation();
  const answers = getAnswersFromLocation(location);
  return (
    <AnswersProvider value={answers}>
      <ProductSearchPage
        products={products}
        sx={{
          fontFamily: "text",
          backgroundColor: "background",
          marginX: "auto",
          padding: [1, 8],
          maxWidth: "frame",
        }}
      />
    </AnswersProvider>
  );
}
