/** @jsxImportSource theme-ui */
// @ts-check
import { Link } from "react-router-dom";
import { Box, Radio, Text } from "theme-ui";
import useAnswerLink from "./useAnswerLink";
import useAnswers from "./useAnswers";

export default function LinkedRadio({ name, value, children, ...rest }) {
  const answers = useAnswers();
  const isChecked = String(answers[name]) === value;
  const getLink = useAnswerLink();
  return (
    <Link
      to={getLink({ [name]: value })}
      replace
      sx={{ textDecoration: "none", color: "inherit" }}
    >
      <Box
        as="label"
        sx={{ marginBottom: 4, display: "flex", alignItems: "flex-start" }}
        {...rest}
      >
        <Radio name={name} value={value} checked={isChecked} readOnly />{" "}
        <Text sx={{ display: "inline-block", maxWidth: "paragraph" }}>
          {children}
        </Text>
      </Box>
    </Link>
  );
}
