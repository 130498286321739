/** @jsxImportSource theme-ui */
import { useLocation } from "react-router-dom";
import getAnswersFromLocation from "../components/getAnswersFromLocation";
import ProductSearchPage from "../components/ProductSearchPage/ProductSearchPage";
import { AnswersProvider } from "../components/ProductSearchPage/useAnswers";
import StandaloneLayout from "../components/theme/StandaloneLayout";
import products from "../products";

export default function SearchPage() {
  /** @type {{ state: { answers?: import("../rankProducts").Answers} }} */
  const location = useLocation();
  const answers = getAnswersFromLocation(location);
  return (
    <AnswersProvider value={{ section: "ergebnis", ...answers }}>
      <StandaloneLayout>
        <ProductSearchPage
          products={products}
          initialSection="standalone"
          sx={{
            fontFamily: "text",
            backgroundColor: "background",
            marginX: "auto",
            padding: [1, 8],
            maxWidth: "frame",
          }}
        />
      </StandaloneLayout>
    </AnswersProvider>
  );
}
