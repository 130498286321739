/** @jsxImportSource theme-ui */
// @ts-check
import { Box } from "theme-ui";
import { toEmailValues } from "./ProductFilters";

export default function ResultsPanel({ products, answers }) {
  const {
    antworten,
    ergebnislink,
    geeigneteinvestitionsarten,
    wenigergeeigneteinvestitionsarten,
  } = toEmailValues(answers, products);

  return (
    <Box>
      <input
        type="hidden"
        name="tx_powermail_pi1[field][antworten]"
        value={antworten}
      />
      <input
        type="hidden"
        name="tx_powermail_pi1[field][geeigneteinvestitionsarten]"
        value={geeigneteinvestitionsarten}
      />
      <input
        type="hidden"
        name="tx_powermail_pi1[field][wenigergeeigneteinvestitionsarten]"
        value={wenigergeeigneteinvestitionsarten}
      />
      <input
        type="hidden"
        name="tx_powermail_pi1[field][ergebnislink]"
        value={ergebnislink}
      />

      <input
        type="hidden"
        name="tx_powermail_pi1[__referrer][@extension]"
        value="Powermail"
      />
      <input
        type="hidden"
        name="tx_powermail_pi1[__referrer][@vendor]"
        value="In2code"
      />
      <input
        type="hidden"
        name="tx_powermail_pi1[__referrer][@controller]"
        value="Form"
      />
      <input
        type="hidden"
        name="tx_powermail_pi1[__referrer][@action]"
        value="form"
      />
      <input
        type="hidden"
        name="tx_powermail_pi1[__referrer][arguments]"
        value="YTowOnt9c07aebfa7aa7b38771b8d5d12de4036ac5d77c4d"
      />
      <input
        type="hidden"
        name="tx_powermail_pi1[__referrer][@request]"
        value='a:4:{s:10:"@extension";s:9:"Powermail";s:11:"@controller";s:4:"Form";s:7:"@action";s:4:"form";s:7:"@vendor";s:7:"In2code";}ffbe813716358b6efdf2dc424684b4d25715181b'
      />
    </Box>
  );
}
