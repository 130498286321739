/** @jsxImportSource theme-ui */
// @ts-check
import { Box, Flex, Heading } from "theme-ui";
import { getLaufendeMittel } from "../../getSummary";
import LinkedRadio from "../LinkedRadio";
import useLink from "../useAnswerLink";
import useAnswers from "../useAnswers";
import CollapsedSection from "./CollapsedSection";
import ExpandedSection from "./ExpandedSection";
import InfoBox from "./InfoBox";

export default function LaufendeMittel({ ...rest }) {
  const answers = useAnswers();
  const getLink = useLink();
  if (answers.section !== "laufendeMittel") {
    return (
      <CollapsedSection to={getLink({ section: "laufendeMittel" })}>
        <CollapsedSection.Title>Laufende Mittel:</CollapsedSection.Title>{" "}
        {getLaufendeMittel(answers)}
      </CollapsedSection>
    );
  }

  return (
    <ExpandedSection
      previousLink={getLink({ section: "hebelung" })}
      nextLink={getLink({ section: "veranlagungszeitraum" })}
    >
      <Box {...rest}>
        <InfoBox
          info="Ggf. zur Deckung der laufenden Kosten bzw. zur Rückführung einer möglichen Finanzierung"
          sx={{ marginY: 6 }}
        >
          <Heading as="h3">Laufende Mittel im Bedarfsfall</Heading>
        </InfoBox>
        <Flex sx={{ gap: 4 }}>
          <LinkedRadio name="laufendeEigenmittel" value="true">
            Ja
          </LinkedRadio>
          <LinkedRadio name="laufendeEigenmittel" value="false">
            Nein
          </LinkedRadio>
        </Flex>
      </Box>
    </ExpandedSection>
  );
}
