// @ts-check
import baurechtswohnungseigentum from "./baurechtswohnungseigentum";
import crowdinvesting from "./crowdinvesting";
import dreiJahresHaus from "./dreiJahresHaus";
import ertragswohnung from "./ertragswohnung";
import gmbhBeteiligungBestand from "./gmbhBeteiligungBestand";
import gmbhBeteiligungEntwicklung from "./gmbhBeteiligungEntwicklung";
import grossesBauherrenmodell from "./grossesBauherrenmodell";
import grundstueck from "./grundstueck";
import immobilienaktien from "./immobilienaktien";
import immobilienfonds from "./immobilienfonds";
import immopartsKG from "./immopartsKG";
import immopartsMEG from "./immopartsMEG";
import kgBeteiligungBestand from "./kgBeteiligungBestand";
import kgBeteiligungEntwicklung from "./kgBeteiligungEntwicklung";
import kleinesBauherrenmodell from "./kleinesBauherrenmodell";
import sonstigeVermietobjekte from "./sonstigeVermietobjekte";
import vorsorgewohnung from "./vorsorgewohnung";
import zehnJahresZinshaus from "./zehnJahresZinshaus";
import zinshaus from "./zinshaus";
import zinshausanteilGrundbuch from "./zinshausanteilGrundbuch";

const productDefinitions = {
  grundstueck,
  ertragswohnung,
  vorsorgewohnung,
  zinshaus,
  zinshausanteilGrundbuch,
  zehnJahresZinshaus,
  gmbhBeteiligungEntwicklung,
  kgBeteiligungEntwicklung,
  immopartsKG,
  immopartsMEG,
  gmbhBeteiligungBestand,
  kgBeteiligungBestand,
  dreiJahresHaus,
  kleinesBauherrenmodell,
  grossesBauherrenmodell,
  immobilienaktien,
  immobilienfonds,
  crowdinvesting,
  sonstigeVermietobjekte,
  baurechtswohnungseigentum,
};

export default productDefinitions;
