/** @jsxImportSource theme-ui */
// @ts-check
import { Box, Flex } from "theme-ui";

export default function StandaloneLayout({ children, ...rest }) {
  return (
    <Box {...rest}>
      <Header />
      {children}
    </Box>
  );
}
function Container({ children, ...rest }) {
  return (
    <Box sx={{ maxWidth: "container", marginX: "auto", paddingX: 2 }} {...rest}>
      {children}
    </Box>
  );
}

function Header() {
  return (
    <Box sx={{ backgroundColor: "header", color: "onPrimary" }}>
      <Container
        as={Flex}
        sx={{ paddingY: 3, alignItems: "center", flexGap: 3 }}
      >
        <a
          href="https://www.immoselection.at/"
          sx={{ display: "block", marginX: 3 }}
        >
          <img
            alt=""
            src="https://www.immoselection.at/fileadmin/tpl/gfx/immoselection_logo2020_white_T.png"
            width="123"
            height="100"
          />
        </a>
      </Container>
    </Box>
  );
}
