/** @jsxImportSource theme-ui */
// @ts-check
import { Heading } from "theme-ui";
import { getZiele } from "../../getSummary";
import LinkedCheckbox from "../LinkedCheckbox";
import useAnswerLink from "../useAnswerLink";
import useAnswers from "../useAnswers";
import CollapsedSection from "./CollapsedSection";
import ExpandedSection from "./ExpandedSection";
import InfoBox from "./InfoBox";

export default function Investitionsziele({ ...rest }) {
  const answers = useAnswers();
  const getLink = useAnswerLink();
  if (answers.section !== "ziele" && answers.section !== undefined) {
    return (
      <CollapsedSection to={getLink({ section: "ziele" })}>
        <CollapsedSection.Title>Investitionsziele:</CollapsedSection.Title>{" "}
        {getZiele(answers)}
      </CollapsedSection>
    );
  }

  return (
    <ExpandedSection nextLink={getLink({ section: "eigenmittel" })} {...rest}>
      <InfoBox
        info="Nicht alle Investitionsarten eignen sich zur Erreichung Ihres
          Ziels/Ihrer Ziele"
        sx={{ marginY: 6 }}
      >
        <Heading as="h3">Ziele</Heading>
      </InfoBox>

      <LinkedCheckbox name="vermoegensaufbau">Vermögensaufbau</LinkedCheckbox>
      <LinkedCheckbox name="pensionsvorsorge">Pensionsvorsorge</LinkedCheckbox>
      <LinkedCheckbox name="laufendeEinnahmen">
        Laufende Einnahmen
      </LinkedCheckbox>
      <LinkedCheckbox name="steuerlicheAbschreibung">
        Senkung der Einkommensteuer
      </LinkedCheckbox>
      <LinkedCheckbox name="kapitalsicherung">Kapitalsicherung</LinkedCheckbox>
      <LinkedCheckbox name="sofortigeEigennutzung">
        Eigennutzung sofort
      </LinkedCheckbox>
      <LinkedCheckbox name="spaetereEigennutzung">
        Eigennutzung später
      </LinkedCheckbox>
    </ExpandedSection>
  );
}
