// @ts-check
import productDefinitions from "./_productDefinitions";

// Create an array of products with id, enable them and set the initial sterne.
/**
 * @type {import("../rankProducts").ProductDefinition[]}
 */
const products = Object.keys(productDefinitions).map((id) => ({
  id,
  rankings: [],
  ...productDefinitions[id],
}));

export default products;
