// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const bauherrenmodell = {
  name: "Großes Bauherrenmodell",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/d/b/csm_grosses_bhm_93b09ee0ce.jpg",
  url:
    "https://www.immoselection.at/investitionsarten/grosses-bauherrenmodell/",
  tagline:
    "Bei dieser Investition erwerben Sie Anteile an einem Bauprojekt mit steuerlichen Abschreibungen in den ersten Jahren. Als Bauherr verfügen Sie über Gestaltungs- und Änderungsmöglichkeiten des Konzeptes",
  facts: [
    "Mindestinvestment: ab € 100.000",
    "Veranlagungszeitraum: langfristig - über 20 Jahre",
    "Rendite: lfd. 2,2 – 3,5% p.a. + Wertsteigerung",
  ],
  // Rules also apply to kleines Bauherrenmodell.
  rules: [
    // 1. Investionsziel
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "bedingt",
        grund:
          "Das anteilige Miteigentum kann nicht selbst genutzt werden, da es einen Anteil am Gesamtobjekt darstellt. Außerdem muss bei dieser Veranlagung auf steuerliche Fristen und die Totalgewinnerreichung geachtet werden.",
      },
    },
    {
      wenn: { spaetereEigennutzung: true },
      dann: {
        rang: "bedingt",
        grund:
          "Das anteilige Miteigentum kann nicht selbst genutzt werden, da es einen Anteil am Gesamtobjekt darstellt. Außerdem muss bei dieser Veranlagung auf steuerliche Fristen und die Totalgewinnerreichung geachtet werden.",
      },
    },

    // 3. Finanzierung
    {
      wenn: {
        eigenmittel: "0-50k",
        finanzierung: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Zu geringes Investitionskapital, wenn keine Finanzierung, da das Gesamtinvestment in der Regel über € 100.000 liegt.",
      },
    },

    // 4. Laufende Eigenmittel
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Es werden laufende Eigenmittel für die Rückführung der verschiedenen Kredite benötigt.",
      },
    },
    {
      wenn: {
        eigenmittelersatz: false,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Es werden laufende Eigenmittel für die Rückführung der verschiedenen Kredite benötigt.",
      },
    },
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: false,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "schlecht",
        grund:
          "Bei Mietausfällen könnten lfd. Zuschüsse für die Eigenmittelfinanzierung und das Förderdarlehen notwendig sein.",
      },
    },
    {
      wenn: {
        eigenmittelersatz: false,
        finanzierung: false,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Da i.d.R. ein Förderdarlehen besteht, könnten lfd. Eigenmittelzuschüsse bei Mietausfällen notwendig werden.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: { kapitalbindung: "-5Jahre" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die gewünschte Kapitalbindungszeit ist für diese Veranlagungsform zu gering.",
      },
    },
    {
      wenn: { kapitalbindung: "6-10Jahre" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die gewünschte Kapitalbindungszeit ist für diese Veranlagungsform zu gering.",
      },
    },
    {
      wenn: { kapitalbindung: "11-25Jahre" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die gewünschte Kapitalbindungszeit ist für diese Veranlagungsform zu gering.",
      },
    },
  ],
};

export default bauherrenmodell;
