// @ts-check
import grossesBauherrenmodell from "./grossesBauherrenmodell";
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const kleinesBauherrenmodell = {
  ...grossesBauherrenmodell,
  name: "Kleines Bauherrenmodell",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/1/c/csm_kleine_bhm_07955c2d70.jpg",
  url: "https://www.immoselection.at/investitionsarten/kleines-bauherrenmodell/",

  // Rules are imported from grossesBauherrenmodell.
  tagline:
    "Bei dieser Investition erwerben Sie Anteile an einem Bauprojekt, wobei das gesamte Konzept der Initiator stellt. In den ersten Jahren kommt es zu einer Senkung der Einkommensteuerbasis.",
  facts: [
    "Mindestinvestment: ab € 100.000",
    "Veranlagungszeitraum: langfristig - über 20 Jahre",
    "Rendite: lfd. 2,2 – 3,5% p.a. + Wertsteigerung",
  ],
};

export default kleinesBauherrenmodell;
