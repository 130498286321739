// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const crowdinvesting = {
  name: "Crowdinvesting",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/6/e/csm_crowdinvesting_d1a395f98c.jpg",
  url: "https://www.immoselection.at/investitionsarten/crowdinvesting/",
  tagline:
    "Bei dieser Investitionsmöglichkeit geben Sie einem Unternehmen Kapital, welches dieses für die Finanzierung eines Bauvorhabens verwendet",
  facts: [
    "Mindestinvestment: ab € 500",
    "Veranlagungszeitraum: kurzfristig - 2-5 Jahre",
    "Rendite: 4 – 7% p.a.",
  ],
  rules: [
    // 1. Investionsziel
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: { kapitalsicherung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da dieses Investment einer unbesicherten Unternehmensfinanzierung entspricht, eignet es sich nicht für die Kapitalsicherung.",
      },
    },
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die Eigennutzung durch den Anteilsinhaber ist nicht vorgesehen.",
      },
    },
    {
      wenn: { spaetereEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die Eigennutzung durch den Anteilsinhaber ist nicht vorgesehen.",
      },
    },

    // 3. Finanzierung
    {
      wenn: {
        finanzierung: true,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Das gewährte Nachrangdarlehen wird von den Banken nicht als Sicherheit akzepiert.",
      },
    },

    // 4. Laufende Eigenmittel
    {
      wenn: { eigenmittelersatz: true, laufendeEigenmittel: false },
      dann: {
        rang: "bedingt",
        grund:
          "Auf Grund des Ausfallrisikos sollten für die Finanzierungsrückführung entsprechende Eigenmittel rückgestellt werden.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: { kapitalbindung: "11-25Jahre" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Veranlagungshorizont ist größer als die Laufzeit der Veranlagung.",
      },
    },
    {
      wenn: { kapitalbindung: "25Jahre-" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Veranlagungshorizont ist größer als die Laufzeit der Veranlagung.",
      },
    },
  ],
};

export default crowdinvesting;
