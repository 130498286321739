// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const zinshaus = {
  name: "Zinshaus",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/1/4/csm_zinshaus_anleger_e8205501b1.jpg",
  url: "https://www.immoselection.at/investitionsarten/zinshaus/",
  tagline:
    "Beim Zinshausinvestment erwerben Sie ein Objekt mit mehreren vermietbaren Einheiten, welches Sie als alleiniger Eigentümer besitzen und vermieten",
  facts: [
    "Mindestinvestment: ab € 1.500.000",
    "Veranlagungszeitraum: langfristig - über 20 Jahre",
    "Rendite: lfd. 1,5 – 6% p.a. + Wertsteigerung",
  ],
  rules: [
    // 1. Investionsziel
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },

    // 2. Gesamtinvestment
    {
      // Eigenmittelersatz ist irrelevant (-50k mit oder ohne Eigenmittelersatz).
      wenn: { eigenmittel: "0-50k" },
      dann: {
        rang: "ungeeignet",
        grund: "Zu geringe Eigenmittel.",
      },
    },

    // 3. Finanzierung
    {
      wenn: {
        eigenmittel: "50k-250k",
        finanzierung: false,
      },
      dann: {
        rang: "ungeeignet",
        grund: "Zu geringes Investitionskapital, wenn keine Finanzierung.",
      },
    },
    {
      wenn: {
        eigenmittel: "250k-500k",
        finanzierung: false,
      },
      dann: {
        rang: "ungeeignet",
        grund: "Zu geringes Investitionskapital, wenn keine Finanzierung.",
      },
    },

    // 4. laufende Eigenmittel
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da die Eigenmittel finanziert sind und die Investition mittels Finanzierung erhöht wurde, reichen die Mieteinnahmen nicht für die Rückführung.",
      },
    },

    {
      wenn: {
        eigenmittelersatz: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },

    {
      wenn: {
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: {
        kapitalbindung: "-5Jahre",
      },
      dann: {
        rang: "bedingt",
        grund:
          "Durch die kurze Kapitalbindung ist fraglich, ob die Wertentwicklung die angefallenen Nebenkosten beim Ankauf bereits übersteigt.",
      },
    },
  ],
};

export default zinshaus;
