// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const ertragswohnung = {
  name: "Ertragswohnung",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/4/1/csm_ertragswohnung_a0192235dd.jpg",
  url: "https://www.immoselection.at/investitionsarten/ertragswohnung/",
  tagline:
    "Bei der Ertragswohnung investieren Sie in eine bestehende und eventuell schon vermietete Eigentumswohnung",
  facts: [
    "Mindestinvestment: ab € 75.000",
    "Veranlagungszeitraum: langfristig - über 20 Jahre",
    "Rendite: lfd. 2,5 – 4% p.a. + Wertsteigerung",
  ],
  rules: [
    // 1. Investionsziel
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },

    // 2. Finanzierung
    {
      wenn: {
        eigenmittel: "0-50k",
        finanzierung: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Das Investitionskapital ist ohne zusätzlicher Finanzierung zu gering.",
      },
    },

    // 4. Laufende Eigenmittel
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da die Eigenmittel finanziert sind und die Investition mittels Finanzierung erhöht wurde, reichen die Mieteinnahmen nicht für die Rückführung.",
      },
    },

    {
      wenn: {
        eigenmittelersatz: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },

    {
      wenn: {
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: {
        kapitalbindung: "-5Jahre",
      },
      dann: {
        rang: "bedingt",
        grund:
          "Auf Grund der kurzen gewünschten Kapitalbindung ist es fraglich, ob die Kaufnebenkosten mit der Wertsteigerung bereits verdient wurde.",
      },
    },
  ],
};

export default ertragswohnung;
