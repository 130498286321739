/** @jsxImportSource theme-ui */
// @ts-check
import uniqBy from "lodash/uniqBy";
import React from "react";
import { Box, Flex, Heading, IconButton, Text } from "theme-ui";
import Button from "../theme/Button";

/**
 * A compact representation of a product.
 * @param {object} props
 * @param {import("../../rankProducts").RankedProduct & { isExpandedInitially?: boolean, image: string }} props.product
 * @returns
 */

export default function ProductListItem({ product, ...rest }) {
  const variant = getVariant(product);
  const [isOpen, setOpen] = React.useState(false);
  const reasons = uniqBy(
    product.rankings.filter(({ grund }) => Boolean(grund)),
    ({ grund }) => grund
  );
  const hasReasons = reasons.length > 0;
  return (
    <Box sx={{ gridColumn: isOpen ? "1 / -1" : "auto" }} {...rest}>
      <Box
        sx={{
          border: 2,
          borderRadius: "default",
          variant: `product.${variant}`,
        }}
      >
        <Button
          onClick={() => setOpen(!isOpen)}
          variant="text"
          sx={{
            borderRadius: "none",
            display: "flex",
            alignItems: "center",
            width: "100%",
            cursor: "pointer",
            paddingX: 1,
            variant: `product.details.${variant}`,
          }}
        >
          <Heading
            as="h3"
            sx={{
              margin: 0,
              padding: 1,
              textTransform: "uppercase",
              fontSize: 2,
              textAlign: "center",
              flex: 1,
            }}
          >
            {product.name}
          </Heading>
          <IconButton
            as="span"
            title="Details anzeigen"
            sx={{
              // @ts-ignore
              color: (theme) => theme.product.details[variant]?.backgroundColor,
              // @ts-ignore
              backgroundColor: (theme) => theme.product.details[variant]?.color,
              borderRadius: "round",
              width: 20,
              height: 20,
              marginY: 0,
              position: "relative",
              outline: "none",
              cursor: "pointer",
            }}
          >
            ℹ︎
          </IconButton>
        </Button>
        {isOpen && (
          <Flex
            sx={{
              flexDirection: "row-reverse",
              flexWrap: "wrap",
              backgroundColor: "white",
              color: "text",
            }}
          >
            <ProductDetailImage product={product} sx={{ flex: "1 0 200px" }} />

            <ProductDescription product={product} sx={{ flex: "1 0 200px" }} />

            {hasReasons && (
              <RankingReasons
                reasons={reasons}
                variant={variant}
                sx={{
                  flex: "1 0 200px",
                  borderTop: 1,
                  variant: `product.${variant}`,
                  marginTop: "-1px",
                }}
              />
            )}
          </Flex>
        )}
      </Box>
    </Box>
  );
}

function RankingReasons({ reasons, variant, ...rest }) {
  return (
    <Box sx={{ padding: 2 }} {...rest}>
      <Text sx={{ whiteSpace: "normal", fontFamily: "heading" }}>
        {reasonHeaders[variant] || "Begründung"}
      </Text>

      {reasons.map((reason) => (
        <Flex key={reason.grund} sx={{ alignItems: "baseline" }}>
          <BulletPoint variant={reason.rang} sx={{ marginRight: 2 }} />
          <Text
            variant="small"
            sx={{
              paddingBottom: 2,
              flex: "1",
              maxWidth: "paragraph",
            }}
          >
            {reason.grund}
          </Text>
        </Flex>
      ))}
    </Box>
  );
}

function ProductDescription({ product, ...rest }) {
  return (
    <Box sx={{ padding: 2 }} {...rest}>
      <Text sx={{ whiteSpace: "normal", fontFamily: "heading" }}>
        {product.tagline}
      </Text>
      {product.facts?.length ? (
        <ul sx={{ marginTop: 4 }}>
          {product.facts.map((fact, index) => (
            <Text as="li" key={index}>
              {fact}
            </Text>
          ))}
        </ul>
      ) : null}
    </Box>
  );
}

function ProductDetailImage({ product, ...rest }) {
  return (
    <LinkedProductImage
      product={product}
      sx={{ position: "relative" }}
      {...rest}
    >
      <Button
        sx={{ position: "absolute", bottom: 0, right: 0, cursor: "pointer" }}
        variant="accent"
      >
        Mehr erfahren…
      </Button>
    </LinkedProductImage>
  );
}

const reasonHeaders = {
  gut: "Gut geeignet, denn:",
  neutral: "Geeignet, denn:",
  bedingt: "Weniger geeignet, denn:",
  schlecht: "Schlecht geeignet, denn:",
  ungeeignet: "Ungeeignet, denn:",
};

function BulletPoint({ variant, ...rest }) {
  return (
    <Box sx={{ variant: `bulletpoint.${variant}`, paddingX: 1 }} {...rest}>
      ●
    </Box>
  );
}

/**
 *
 * @param {import("../../rankProducts").ProductRank} product
 * @returns { import("../../theme").ProductVariant}
 */
function getVariant(product) {
  if (product.lowestRanking) return product.lowestRanking.rang;
  return "neutral";
}

/**
 *
 * @param {object} props
 * @param {import("../../rankProducts").RankedProduct & { image: string }} props.product
 * @param {boolean} [props.noLink]
 * @param {number} [props.width]
 * @param {number} [props.height]
 * @param {React.ReactNode} [props.children]
 * @returns
 */
function LinkedProductImage({
  product,
  noLink = false,
  width = 360,
  height = 202,
  /** @type {React.ReactNode}*/ children,
  ...rest
}) {
  if (product.url && !noLink) {
    return (
      <a
        href={product.url}
        target="_top"
        sx={{ display: "block", overflow: "hidden" }}
        {...rest}
      >
        <LinkedProductImage
          product={{ ...product, url: undefined }}
          sx={{ ":hover": { transform: "scale(1.05)" } }}
        />
        {children}
      </a>
    );
  }

  return (
    <img
      src={product.image}
      alt={product.name}
      width={width}
      height={height}
      sx={{
        display: "block",
        transition: "all 0.3s ease-out",
        variant: `cardImage.${product.lowestRanking?.rang}`,
        height: "100%",
        width: "100%",
        objectFit: "cover",
        ":hover": { filter: "none", opacity: 1 },
      }}
      {...rest}
    />
  );
}
