// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const kgBeteiligungAnleger = {
  name: "KG-Beteiligung Bestand",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/7/1/csm_kg_anleger_5fc304b7f9.jpg",
  url: "https://www.immoselection.at/investitionsarten/kg-beteiligung-bestand/",
  tagline:
    "Hier erwerben Sie einen Anteil an einer Kommanditgesellschaft (oder GmbH & Co KG), welche eine fertige und vermietete Immobilie besitzt.",
  facts: [
    "Mindestinvestment: ab € 100.000",
    "Veranlagungszeitraum: mittelfristig - über 10 Jahre",
    "Rendite: lfd. 2,5 - 4% aus Miete und Wertsteigerung",
  ],
  rules: [
    // 1. Investionsziel
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Eine Nutzung der Immobilie durch die Kommanditisten ist nur zu fremdüblichen Mietkonditionen möglich. (Ausnahme: mit erfolgter Zustimmung der anderen Beteiligten).",
      },
    },
    {
      wenn: { spaetereEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Eine Nutzung der Immobilie durch die Kommanditisten ist nur zu fremdüblichen Mietkonditionen möglich. (Ausnahme: mit erfolgter Zustimmung der anderen Beteiligten).",
      },
    },

    // 3. Finanzierung
    {
      wenn: {
        eigenmittel: "0-50k",
        finanzierung: false,
      },
      dann: {
        rang: "ungeeignet",
        grund: "zu geringes Investitionskapital, wenn keine Finanzierung.",
      },
    },

    // 4. Laufende Eigenmittel
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da die Eigenmittel finanziert sind und die Investition mittels Finanzierung erhöht wurde, reichen die Mieteinnahmen nicht für die Rückführung.",
      },
    },
    {
      wenn: {
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Bei einem Ausfall der Einnahmen sollten lfd. Eigenmittel für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },
    {
      wenn: {
        eigenmittelersatz: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Bei einem Ausfall der Einnahmen sollten lfd. Eigenmittel für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },
  ],
};

export default kgBeteiligungAnleger;
