// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const dreiJahresHaus = {
  name: "3-Jahres-Haus",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/5/5/csm_3jahres_haus_eaa507b23f.jpg",
  url: "https://www.immoselection.at/investitionsarten/3-jahres-haus/",
  tagline:
    "Bei einem 3-Jahres-Haus erhalten Sie die Möglichkeit, sich temporär an einer Projektgesellschaft – einer Immobilien GmbH – zu beteiligen",
  facts: [
    "Mindestinvestment: € 100.000",
    "Veranlagungszeitraum: kurzfristig -zwischen 3 - 5 Jahre",
    "Rendite: 4,7 – 5,5% p.a.",
  ],
  rules: [
    // 1. Investionsziel
    {
      wenn: { laufendeEinnahmen: true },
      dann: {
        rang: "ungeeignet",
        grund: "Diese Veranlagung bietet keine laufenden Einnahmen.",
      },
    },
    {
      wenn: { pensionsvorsorge: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch den fixierten Verkauf ist es nicht als Pensionsvorsorge geeignet.",
      },
    },
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Eine Eigennutzung ist bei dieser Veranlagung nicht vorgesehen. Und die Immobilie wird erst entwickelt, sprich saniert oder erbaut, wodurch die Nutzung nicht möglich ist.",
      },
    },
    {
      wenn: { spaetereEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Eine Eigennutzung ist bei dieser Veranlagung nicht vorgesehen. Nach der Entwicklung der Immobilie und dem Ende der Veranlagung kann (bei manchen Projekten) in Folge Wohnungseigentum erworben werden.",
      },
    },
    {
      wenn: { kapitalsicherung: true },
      dann: {
        rang: "bedingt",
        grund:
          "Wenn die Entwicklung der Immobilie nicht erfolgen kann und die rückkaufende Gesellschaft den Rückkauf nicht bezahlen kann, ist der Kapitalerhalt nicht gewährleistet.",
      },
    },

    // 2. Gesamtinvestment
    {
      // Eigenmittelersatz ist irrelevant (-50k mit oder ohne Eigenmittelersatz).
      wenn: { eigenmittel: "0-50k" },
      dann: {
        rang: "ungeeignet",
        grund: "Das Mindestinvestment dieser Veranlagung liegt bei € 100.000.",
      },
    },

    // 3. Finanzierung
    {
      wenn: { finanzierung: true },
      dann: {
        rang: "bedingt",
        grund:
          "Die Banken nehmen diese Anteile ungern als Besicherung. Für gute Bonitäten ist die Finanzierung durch Zession des Verkaufserlöses aber möglich.",
      },
    },

    // 4. Laufende Eigenmittel

    {
      wenn: {
        eigenmittelersatz: false,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Da das Investment keinen laufenden Ertrag bietet, muss bei einer Hebel-Finanzierung ohne weiteren Eigenmitteleinsatz während der Laufzeit sowohl Spesen als auch Zinsen des Kredits mitfinanziert werden.",
      },
    },

    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Da das Investment keinen laufenden Ertrag bietet, muss bei einer Finanzierung der Eigenmittel als auch einer weiteren Hebelfinanzierung ohne weiteren Eigenmitteleinsatz während der Laufzeit sowohl Spesen als auch Zinsen des Kredits mitfinanziert werden.",
      },
    },

    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: false,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Da das Investment keinen laufenden Ertrag bietet, muss bei einer Eigenmittel-Finanzierung ohne weiteren Eigenmitteleinsatz während der Laufzeit sowohl Spesen als auch Zinsen des Kredits mitfinanziert werden.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: { kapitalbindung: "6-10Jahre" },
      dann: {
        rang: "bedingt",
        grund:
          "Die Veranlagungslaufzeit ist geringer als der persönliche Veranlagungshorizont.",
      },
    },
    {
      wenn: { kapitalbindung: "11-25Jahre" },
      dann: {
        rang: "bedingt",
        grund:
          "Die Veranlagungslaufzeit ist geringer als der persönliche Veranlagungshorizont.",
      },
    },
    {
      wenn: { kapitalbindung: "25Jahre-" },
      dann: {
        rang: "bedingt",
        grund:
          "Die Veranlagungslaufzeit ist geringer als der persönliche Veranlagungshorizont.",
      },
    },
  ],
};

export default dreiJahresHaus;
