/** @jsxImportSource theme-ui */
// @ts-check
import { Link, useLocation } from "react-router-dom";
import { Box, Grid } from "theme-ui";
import groupResults from "../../groupResults";
import rankProducts from "../../rankProducts";
import getQueryString from "../getQueryString";
import getSummary from "../getSummary";
import ResultsPanel from "./ResultsPanel";
import Eigenmittel from "./sections/Eigenmittel";
import Finalize from "./sections/Finalize";
import Hebelung from "./sections/Hebelung";
import Investitionsziele from "./sections/Investitionsziele";
import LaufendeMittel from "./sections/LaufendeMittel";
import Veranlagungszeitraum from "./sections/Veranlagungszeitraum";
import SendButton from "./SendButton";
import useAnswers from "./useAnswers";

/** @typedef {'SEND_RESULTS' | 'REQUEST_OFFER'} FormAction */

export default function ProductFilters({ products, ...rest }) {
  /** @type {[FormAction, import('react').Dispatch<React.SetStateAction<FormAction>>]} */
  // @ts-ignore
  const location = useLocation();
  const answers = useAnswers();

  return (
    <Box {...rest}>
      <Grid gap={4}>
        <Investitionsziele />
        <Eigenmittel />
        <Hebelung />
        <LaufendeMittel />
        <Veranlagungszeitraum />
        <Finalize />

        <ResultsPanel products={products} answers={answers} />
      </Grid>

      <SendButton />

      <ResetLink to={location.pathname} sx={{ marginY: 6 }}>
        Filter zurücksetzen
      </ResetLink>
    </Box>
  );
}

function ResetLink({ to, children, ...rest }) {
  return (
    <Link
      to={to}
      sx={{
        display: "block",
        paddingY: 2,
        color: "primary",
        textDecoration: "none",
        ":hover": { textDecoration: "underline" },
      }}
      {...rest}
    >
      {children}
    </Link>
  );
}

export function toEmailValues(answers, products) {
  const antworten = getSummary(answers);
  const results = groupResults(rankProducts(answers, products));

  const geeigneteinvestitionsarten = results.gut
    .map((product) => product.name)
    .join(", ");
  const wenigergeeigneteinvestitionsarten = results.bedingt
    .concat(results.schlecht)
    .map((product) => product.name)
    .join(", ");
  const queryString = getQueryString(answers, ["section"]);
  return {
    antworten,
    geeigneteinvestitionsarten,
    wenigergeeigneteinvestitionsarten,
    ergebnislink: `https://fragebogen.immoselection.at/standalone?${queryString}`,
  };
}
