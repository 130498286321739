// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const kgBeteiligungEntwicklung = {
  name: "KG-Beteiligung Entwicklung",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/6/a/csm_kg_investoren_d34d3072b5.jpg",
  url:
    "https://www.immoselection.at/investitionsarten/kg-beteiligung-entwicklung/",
  tagline:
    "Sie erwerben allein oder gemeinsam mit mehreren Investoren eine KG, welche für den Ankauf und die Entwicklung einer konkreten Immobilie gegründet wurde",
  facts: [
    "Mindestinvestment: € 100.000",
    "Veranlagungszeitraum: kurzfristig -zwischen 1 - 4 Jahre",
    "Rendite: 7 - 12% p.a.",
  ],

  rules: [
    // 1. Investionsziel
    {
      wenn: { laufendeEinnahmen: true },
      dann: {
        rang: "ungeeignet",
        grund: "Diese Veranlagungsform bringt keine laufenden Einnahmen.",
      },
    },
    {
      wenn: { pensionsvorsorge: true },
      dann: {
        rang: "bedingt",
        grund:
          "Durch den fixierten Verkauf und die fehlenden laufenden Einnahmen ist diese Investition als Pensionsvorsorge eher ungeeignet.",
      },
    },
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: { kapitalsicherung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Auf Grund der mitzutragenden Risiken des Investments ist es als Kapitalsicherungsinstrument ungeeignet.",
      },
    },
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da bei dieser Veranlagung die Immobilie entwickelt, sprich saniert oder gebaut, und wieder verkauft wird, ist eine Eigennutzung nicht möglich.",
      },
    },
    {
      wenn: { spaetereEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da bei dieser Veranlagung die Immobilie entwickelt, sprich saniert oder gebaut, und wieder verkauft wird, ist eine Eigennutzung nicht möglich.",
      },
    },

    // 2. Gesamtinvestment
    {
      // Eigenmittelersatz ist irrelevant (-50k mit oder ohne Eigenmittelersatz).
      wenn: { eigenmittel: "0-50k" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die Mindestinvestition liegt in der Regel bei € 100.000 Eigenmittel.",
      },
    },

    // 4. Laufende Eigenmittel
    {
      wenn: {
        eigenmittelersatz: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die meist endfällige Finanzierunggestaltung werden zumindest für den laufenden Zinsendienst entsprechende Eigenmittel benötigt.",
      },
    },
    {
      wenn: {
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die meist endfällige Finanzierunggestaltung werden zumindest für den laufenden Zinsendienst entsprechende Eigenmittel benötigt.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: { kapitalbindung: "6-10Jahre" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Veranlagungshorizont ist größer als die Laufzeit der Veranlagung.",
      },
    },
    {
      wenn: { kapitalbindung: "11-25Jahre" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Veranlagungshorizont ist größer als die Laufzeit der Veranlagung.",
      },
    },
    {
      wenn: { kapitalbindung: "25Jahre-" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Veranlagungshorizont ist größer als die Laufzeit der Veranlagung.",
      },
    },
  ],
};

export default kgBeteiligungEntwicklung;
