/** @jsxImportSource theme-ui */
// @ts-check
import { Box, Flex, Heading } from "theme-ui";
import { getEigenmittel } from "../../getSummary";
import LinkedCheckbox from "../LinkedCheckbox";
import LinkedRadio from "../LinkedRadio";
import useLink from "../useAnswerLink";
import useAnswers from "../useAnswers";
import CollapsedSection from "./CollapsedSection";
import ExpandedSection from "./ExpandedSection";
import InfoBox from "./InfoBox";

export default function Eigenmittel({ ...rest }) {
  const answers = useAnswers();
  const getLink = useLink();
  if (answers.section !== "eigenmittel") {
    return (
      <CollapsedSection to={getLink({ section: "eigenmittel" })}>
        <CollapsedSection.Title>Eigenmittel:</CollapsedSection.Title>{" "}
        {getEigenmittel(answers)}
      </CollapsedSection>
    );
  }

  return (
    <ExpandedSection
      previousLink={getLink({ section: "ziele" })}
      nextLink={getLink({ section: "hebelung" })}
    >
      <Box {...rest}>
        <InfoBox
          info="Die Höhe der Eigenmittel, die Sie für die Investition zur Verfügung stellen wollen/können"
          sx={{ marginY: 6 }}
        >
          <Heading as="h3">Eigenmittel</Heading>
        </InfoBox>
        <LinkedRadio name="eigenmittel" value="0-50k">
          Bis € 50.000
        </LinkedRadio>
        <LinkedRadio name="eigenmittel" value="50k-250k">
          € 50.001 – € 250.000
        </LinkedRadio>
        <LinkedRadio name="eigenmittel" value="250k-500k">
          € 250.001 – € 500.000
        </LinkedRadio>
        <LinkedRadio name="eigenmittel" value="500k-">
          Ab € 500.000
        </LinkedRadio>
      </Box>

      <Flex sx={{ gap: 2, marginY: 6, alignItems: "flex-start" }}>
        <InfoBox
          info="Eigenmittel können auch belehnbare Sachwerte sein wie z.B. Wertpapiere, Kapitalversicherungen, Immobilien etc."
          hasClickableChildren
        >
          <LinkedCheckbox name="eigenmittelersatz" sx={{ marginY: 0 }}>
            Sachwerte
          </LinkedCheckbox>
        </InfoBox>
      </Flex>
    </ExpandedSection>
  );
}
