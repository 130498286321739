/** @jsxImportSource theme-ui */
export default function Form({ children, ...rest }) {
  return (
    <form
      action={
        "https://www.immoselection.at/fragebogen/beratung/?tx_powermail_pi1%5Baction%5D=create&tx_powermail_pi1%5Bcontroller%5D=Form&cHash=0ed3dd47491b985650ee97a81dd17030"
      }
      method="post"
      target="_top"
      {...rest}
    >
      {children}
    </form>
  );
}
