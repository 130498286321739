// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const grundstueck = {
  name: "Grundstück",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/1/f/csm_grundstueck_5f68843bbd.jpg",
  url: "https://www.immoselection.at/investitionsarten/grundstueck/",
  tagline:
    "Ein Grundstück kann zur Kapitalsicherung bzw. -vermehrung angeschafft werden",
  facts: [
    "Mindestinvestment: ab € 60.000",
    "Veranlagungszeitraum: mittel bis langfristig - ab 10 Jahre",
    "Rendite: 0-100%",
  ],

  rules: [
    // 1. Investionsziel
    {
      wenn: { laufendeEinnahmen: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Nennenswerte laufende Einnahmen sind bei reinen Grundstücken Ausnahmen und erfordern entsprechendes Vorwissen (Vergabe eines Baurechts, Nutzung als Parkplatz).",
      },
    },
    {
      wenn: { pensionsvorsorge: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Auf Grund der fehlenden laufenden Einnahmen ist das Grundstück nicht ideal für die Pensionsvorsorge.",
      },
    },
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },

    // 4. laufende Eigenmittel
    {
      wenn: {
        eigenmittelersatz: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da das Grundstück keinen laufenden Ertrag hat, werden laufende Mittel für die Finanzierungrückführung benötigt.",
      },
    },
    {
      wenn: {
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da das Grundstück keinen laufenden Ertrag hat, werden laufende Mittel für die Finanzierungrückführung benötigt.",
      },
    },
  ],
};

export default grundstueck;
