/** @jsxImportSource theme-ui */
// @ts-check
import { Flex } from "theme-ui";
import Button from "../theme/Button";
import { FaCalculator } from "react-icons/fa";

export default function SendButton(props) {
  return (
    <Flex sx={{ flexWrap: "wrap", gap: 3 }} {...props}>
      <Button
        variant="accent"
        type="submit"
        sx={{
          cursor: "pointer",
          flexGrow: 1,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "400",
        }}
      >
        <FaCalculator sx={{ marginRight: 3 }} /> Angebot/Kalkulation erhalten
      </Button>
    </Flex>
  );
}
