/** @jsxImportSource theme-ui */
// @ts-check
import { Box, Grid } from "theme-ui";
import Subtitle from "../theme/Subtitle";
import ProductListItem from "./ProductListItem";

export default function ProductList({
  products,
  isExpanded = false,
  title,
  ...rest
}) {
  if (!products.length) return null;
  return (
    <Box {...rest}>
      <Subtitle sx={{ marginBottom: 4 }}>{title}</Subtitle>
      <Grid width={250} gap={1}>
        {products.map((product) => (
          <ProductListItem key={product.id} product={product} />
        ))}
      </Grid>
    </Box>
  );
}
