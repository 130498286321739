// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const gmbhBeteiligungEntwicklung = {
  name: "GmbH-Beteiligung Entwicklung",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/0/7/csm_gmbh_investoren_ad0e833d9a.jpg",
  url:
    "https://www.immoselection.at/investitionsarten/gmbh-beteiligung-entwicklung/",
  tagline:
    "Sie erwerben allein oder gemeinsam mit mehreren Investoren eine GmbH, welche für den Ankauf und die Entwicklung einer konkreten Immobilie gegründet wurde",
  facts: [
    "Mindestinvestment: € 100.000",
    "Veranlagungszeitraum: kurzfristig -zwischen 3 - 5 Jahre",
    "Rendite: 7 - 12% p.a.",
  ],

  rules: [
    // 1. Investionsziel
    {
      wenn: { laufendeEinnahmen: true },
      dann: {
        rang: "ungeeignet",
        grund: "Diese Veranlagung bietet keine laufenden Einnahmen.",
      },
    },
    {
      wenn: { pensionsvorsorge: true },
      dann: {
        rang: "bedingt",
        grund:
          "Durch den fixierten Verkauf und die fehlenden laufenden Einnahmen ist diese Investition als Pensionsvorsorge eher ungeeignet.",
      },
    },
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: { kapitalsicherung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch das übernommene unternehmersiche Risiko ist diese Veranlagung für die Kapitalsicherung ungeeignet.",
      },
    },
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da bei dieser Veranlagung die Immobilie entwickelt, sprich saniert oder gebaut, und wieder verkauft wird, ist eine Eigennutzung nicht möglich.",
      },
    },
    {
      wenn: { spaetereEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da bei dieser Veranlagung die Immobilie entwickelt, sprich saniert oder gebaut, und wieder verkauft wird, ist eine Eigennutzung nicht möglich.",
      },
    },

    // 2. Gesamtinvestment
    {
      wenn: { eigenmittel: "0-50k" },
      dann: {
        rang: "ungeeignet",
        grund: "Zu geringes Investitionskapital.",
      },
    },

    // 3. Finanzierung
    {
      wenn: {
        finanzierung: true,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die Hebelung des Investments durch eine Finanzierung mit Besicherung durch die Anteile ist nicht erlaubt.",
      },
    },

    // 4. Laufende Eigenmittel
    {
      wenn: { eigenmittelersatz: true, laufendeEigenmittel: false },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da das Investment keinen laufenden Ertrag hat, werden lfd. Mittel für die Eigenmittelfinanzierung benötigt.",
      },
    },
    // 5. Kapitalbindung
    {
      wenn: { kapitalbindung: "-5Jahre" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die gewünschte Kapitalbindungszeit ist für diese Veranlagungsform zu gering.",
      },
    },
    {
      wenn: { kapitalbindung: "11-25Jahre" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Veranlagungshorizont ist größer als die Laufzeit der Veranlagung.",
      },
    },
    {
      wenn: { kapitalbindung: "25Jahre-" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Veranlagungshorizont ist größer als die Laufzeit der Veranlagung.",
      },
    },
  ],
};

export default gmbhBeteiligungEntwicklung;
