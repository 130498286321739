/** @jsxImportSource theme-ui */
// @ts-check
import useLink from "../useAnswerLink";
import useAnswers from "../useAnswers";
import ExpandedSection from "./ExpandedSection";

export default function Finalize() {
  const answers = useAnswers();
  const getLink = useLink();
  if (answers.section !== "ergebnis") return null;

  return (
    <ExpandedSection
      previousLink={getLink({ section: "veranlagungszeitraum" })}
    />
  );
}
