/** @jsxImportSource theme-ui */
// @ts-check
import React from "react";
import { Box, Flex, IconButton, Text } from "theme-ui";

export default function InfoBox({
  info,
  children,
  hasClickableChildren = false,
  ...rest
}) {
  const [visible, setVisible] = React.useState(false);
  return (
    <Box
      onClick={() => !hasClickableChildren && setVisible(!visible)}
      sx={{ cursor: "pointer" }}
      {...rest}
    >
      <Flex sx={{ gap: 2, alignItems: "center" }}>
        {children}
        <IconButton
          type="button"
          onClick={() => hasClickableChildren && setVisible(!visible)}
          title={info}
          sx={{
            color: "onPrimary",
            backgroundColor: "primary",
            borderRadius: "round",
            width: 20,
            height: 20,
            marginY: 0,
            position: "relative",
            outline: "none",
            cursor: "pointer",
            ":focus": { backgroundColor: "secondary" },
            "::after": visible
              ? {
                  content: '""',
                  position: "absolute",
                  border: "8px solid transparent",
                  bottom: 0,
                  marginBottom: -3,
                  borderBottomColor: "primary",
                }
              : {},
          }}
        >
          ℹ︎
        </IconButton>
      </Flex>
      {visible && (
        <Text
          sx={{
            display:'block',
            fontSize: 1,
            maxWidth: "35ch",
            marginY: 2,
            backgroundColor: "primary",
            borderRadius: "large",
            color: "onPrimary",
            padding: 2,
            marginRight: "auto",
            width: "auto",
          }}
        >
          {info}
        </Text>
      )}
    </Box>
  );
}
