// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const zinshausanteilGrundbuch = {
  name: "Zinshausanteil Grundbuch",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/0/d/csm_zinshausanteil_c11c921d3d.jpg",
  url: "https://www.immoselection.at/investitionsarten/zinshausanteil-grundbuch/",
  tagline:
    "Beim im Grundbuch eingetragenen Zinshausanteil erwerben Sie einen ideellen Anteil an einem Zinshaus",
  facts: [
    "Mindestinvestment: ab € 25.000",
    "Veranlagungszeitraum: mittelfristig – über 10 Jahre",
    "Rendite: lfd. 1,5 – 6% p.a. + Wertsteigerung",
  ],
  rules: [
    // 1. Investionsziel
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "bedingt",
        grund:
          "Anteiliges Miteigentum kann nicht selbst genutzt werden, da es einen Anteil am Gesamtobjekt darstellt. Sollten die Anteile einer Wohnung entsprechen, können diese nach erfolgter Parifizierung (=Begründung von Wohnungseigentum) selbst genutzt werden.",
      },
    },
    {
      wenn: { spaetereEigennutzung: true },
      dann: {
        rang: "bedingt",
        grund:
          "Anteiliges Miteigentum kann nicht selbst genutzt werden, da es einen Anteil am Gesamtobjekt darstellt. Sollten die Anteile einer Wohnung entsprechen, können diese nach erfolgter Parifizierung (=Begründung von Wohnungseigentum) selbst genutzt werden.",
      },
    },

    // 4. laufende Eigenmittel
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Da die Eigenmittel finanziert sind und die Investition mittels Finanzierung erhöht wurde, reichen die Mieteinnahmen nicht für die Rückführung.",
      },
    },

    {
      wenn: {
        eigenmittelersatz: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },

    {
      wenn: {
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: {
        kapitalbindung: "-5Jahre",
      },
      dann: {
        rang: "bedingt",
        grund:
          "Durch die kurze Kapitalbindung ist fraglich, ob die Wertentwicklung die angefallenen Nebenkosten beim Ankauf bereits übersteigt.",
      },
    },
  ],
};

export default zinshausanteilGrundbuch;
