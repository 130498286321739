/** @jsxImportSource theme-ui */
// @ts-check
import { Heading } from "theme-ui";

export default function Subtitle({ children, ...rest }) {
  return (
    <Heading as="h3" sx={{ textTransform: "uppercase" }} {...rest}>
      {children}
    </Heading>
  );
}
