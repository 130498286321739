// @ts-check

import kgBeteiligungBestand from "./kgBeteiligungBestand";

/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const immopartsKG = {
  ...kgBeteiligungBestand,
  tagline:
    "Bei diesem Investment erwerben Sie eine Beteiligung an einer Immobilienbesitzgesellschaft",
  facts: [
    "Mindestinvestment: ab € 100.000",
    "Veranlagungszeitraum: mittel- bis langfristig - ab 10 Jahre",
    "Rendite: lfd. 2,5 - 4% Ertrag + Wertsteigerung",
  ],
  name: "Immoparts KG",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/d/3/csm_immoparts_kg_e3fd659fc9.jpg",
  url: "https://www.immoselection.at/investitionsarten/immoparts-kg/",
};

export default immopartsKG;
