import { Box } from "theme-ui";
import Container from "./Container";

export default function PageLayout({ children, ...rest }) {
  return (
    <Box {...rest}>
      <Container>{children}</Container>
    </Box>
  );
}
