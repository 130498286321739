// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const vorsorgewohnung = {
  name: "Vorsorgewohnung",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/3/8/csm_vorsorgewohnung_eab4827cba.jpg",
  url: "https://immoselection.at/investitionsarten/vorsorgewohnung/",
  tagline:
    "Bei der Vorsorgewohnung erwerben Sie eine Neubau- oder generalsanierte Wohnung direkt vom Bauträger",
  facts: [
    "Mindestinvestment: ab € 120.000",
    "Veranlagungszeitraum: langfristig - über 20 Jahre",
    "Rendite: lfd. 2 – 3,5% p.a. + Wertsteigerung",
  ],
  rules: [
    // 1. Investionsziel
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Eine sofortige Eigennutzung einer Vorsorgewohnung führt zum Verlust des bei Kauf gewährten Umsatzsteuervorteils.",
      },
    },

    // 2. Gesamtinvestment
    {
      wenn: {
        eigenmittel: "0-50k",
        finanzierung: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Ohne einer zusätzlichen Finanzierung reichen die Eigenmittel bzw. der Eigenmittelersatz nicht für den Ankauf einer Vorsorgewohnung.",
      },
    },

    // 3. Finanzierung
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da die Eigenmittel finanziert sind und die Investition mittels Finanzierung erhöht wurde, reichen die Mieteinnahmen nicht für die Rückführung.",
      },
    },

    {
      wenn: {
        eigenmittelersatz: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },

    {
      wenn: {
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: { kapitalbindung: "-5Jahre" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Auf Grund der kurzen gewünschten Kapitalbindung ist es fraglich, ob die Kaufnebenkosten sowie die Nachverrechnung der Umsatzsteuer mit der Wertsteigerung bereits verdient wurde.",
      },
    },
    {
      wenn: { kapitalbindung: "6-10Jahre" },
      dann: {
        rang: "bedingt",
        grund:
          "Auf Grund der kurzen gewünschten Kapitalbindung ist es fraglich, ob die Kaufnebenkosten sowie die Nachverrechnung der Umsatzsteuer mit der Wertsteigerung bereits verdient wurde.",
      },
    },
    {
      wenn: { kapitalbindung: "11-25Jahre" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Verkauf führt mindestens noch zu einer Nachversteuerung der Umsatzsteuer.",
      },
    },
  ],
};

export default vorsorgewohnung;
