// @ts-check

/**
 * Apply all rules to the products and rang them based on sterne and ausschliessen.
 *
 * @export
 * @param {Answers} answers
 * @param {RankedProduct[]} products
 * @returns {RankedProduct[]} The ranked product.
 */
export default function rankProducts(answers, products) {
  return products.map((product) => applyRules(product, answers));
}

/**
 * Apply an product's rules to a single product.
 *
 * @param {RankedProduct} product
 * @param {Answers} answers
 * @returns {RankedProduct}
 */
function applyRules(product, answers) {
  if (!product.rules) return product;
  return product.rules.reduce(
    (product, rule) => applyRule(product, rule, answers),
    product
  );
}

/**
 * Apply a single rule.
 *
 * @param {RankedProduct} product
 * @param {Rule} rule
 * @param {Answers} answers
 * @returns {RankedProduct}
 */
function applyRule(product, { wenn, dann }, answers) {
  // Check if all of the rule's conditions are applicable.
  if (
    Object.keys(wenn).some((question) => answers[question] !== wenn[question])
  ) {
    return product;
  }

  // All conditions match, so we apply the rule.
  return {
    ...product,
    lowestRanking: getLowestRanking(product, dann),
    rankings: [...product.rankings, dann],
  };
}

/** @readonly @type {(RankValue | undefined)[]} */
export const rankings = ["gut", undefined, "bedingt", "schlecht", "ungeeignet"];

const rankingLevels = rankings.reduce(
  (levels, ranking, index) => ({
    ...levels,
    // @ts-ignore
    [ranking]: index
  }),
  {}
);

/**
 *
 * @param {RankedProduct} product
 * @param {Ranking} dann
 * @returns {Ranking}
 */
function getLowestRanking(product, dann) {
  if (product.lowestRanking === undefined) return dann;
  if (rankingLevels[product.lowestRanking.rang] < rankingLevels[dann.rang]) {
    return dann;
  }
  return product.lowestRanking;
}
/**
 * @typedef {object} ProductDescription
 * @property {string} name
 * @property {string} [image] The image illustrating the product.
 * @property {string} [url] A link to the product details.
 * @property {string} [tagline] A short one-liner to describe the product.
 * @property {string[]} [facts] ~3 bullet points to hightlight the product's facts.
 */
/**
 * @typedef {object} ProductRules
 * @property {string} [id]
 * @property {Rule[]} rules
 */

/**
 * @typedef {object} ProductRank
 * @property {Ranking} [lowestRanking]
 * @property {Ranking[]} rankings
 */

/**
 * @typedef {ProductDefinition & ProductRank} RankedProduct
 */

/**
 * @typedef {ProductDescription & ProductRules} ProductDefinition
 */

/**
 * @typedef {object} Rule
 * @property {Answers} wenn
 * @property {Ranking} dann
 */

/**
 * @typedef {object} Ranking
 * @property {RankValue} rang
 * @property {string} grund
 */

/**
 * @typedef {"gut" | "bedingt" | "schlecht" | "ungeeignet"} RankValue
 */

/**
 * @typedef { Investitionsziel &
 *   Gesamtinvestment &
 *   Finanzierung &
 *   LaufendeEigenmittel &
 *   Kapitalbindung
 * } Answers
 */


/**
 * @typedef {object} Investitionsziel
 * @property {boolean} [vermoegensaufbau]
 * @property {boolean} [laufendeEinnahmen]
 * @property {boolean} [pensionsvorsorge]
 * @property {boolean} [steuerlicheAbschreibung]
 * @property {boolean} [kapitalsicherung]
 * @property {boolean} [sofortigeEigennutzung]
 * @property {boolean} [spaetereEigennutzung]
 * @property {boolean} [investitionsziele] Dummy for checking the step.
 */

/**
 * @typedef {object} Gesamtinvestment
 * @property {Eigenmittel} [eigenmittel]
 * @property {boolean} [eigenmittelersatz]
 */

/** @typedef {'0-50k'|'50k-250k'|'250k-500k'|'500k-'} Eigenmittel */


/**
 * @typedef {object} Finanzierung
 * @property {boolean} [finanzierung]
 */

/**
 * @typedef {object} LaufendeEigenmittel
 * @property {boolean} [laufendeEigenmittel]
 */

/**
 * @typedef {object} Kapitalbindung
 * @property {KapitalbindungValues} [kapitalbindung]
 */

/** @typedef {'-5Jahre'|'6-10Jahre'|'11-25Jahre'|'25Jahre-'} KapitalbindungValues */
