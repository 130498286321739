/** @jsxImportSource theme-ui */
// @ts-check
import { Box, Flex, Heading } from "theme-ui";
import { getHebelung } from "../../getSummary";
import LinkedRadio from "../LinkedRadio";
import useLink from "../useAnswerLink";
import useAnswers from "../useAnswers";
import CollapsedSection from "./CollapsedSection";
import ExpandedSection from "./ExpandedSection";
import InfoBox from "./InfoBox";

export default function Hebelung({ ...rest }) {
  const answers = useAnswers();
  const getLink = useLink();
  if (answers.section !== "hebelung") {
    return (
      <CollapsedSection to={getLink({ section: "hebelung" })}>
        <CollapsedSection.Title>
          Hebelung mittels Finanzierung:
        </CollapsedSection.Title>{" "}
        {getHebelung(answers)}
      </CollapsedSection>
    );
  }

  return (
    <ExpandedSection
      previousLink={getLink({ section: "eigenmittel" })}
      nextLink={getLink({ section: "laufendeMittel" })}
    >
      <Box {...rest}>
        <InfoBox
          info="Zur Steigerung des Gesamtinvestments kann/ soll eine Finanzierung mit Besicherung am Investment aufgenommen werden"
          sx={{ marginY: 6 }}
        >
          <Heading as="h3">Hebelung mittels Finanzierung</Heading>
        </InfoBox>
        <Flex sx={{ gap: 4 }}>
          <LinkedRadio name="finanzierung" value="true">
            Ja
          </LinkedRadio>
          <LinkedRadio name="finanzierung" value="false">
            Nein
          </LinkedRadio>
        </Flex>
      </Box>
    </ExpandedSection>
  );
}
