import qs from "qs";

export default function getAnswersFromLocation(location) {
  return Object.entries(qs.parse(location?.search?.replace(/^\?/, ""))).reduce(
    (answers, [key, value]) => {
      answers[key] = parseAnswer(value);
      return answers;
    },
    {}
  );
}

function parseAnswer(value) {
  if (value === "true") return true;
  if (value === "false") return false;
  return value;
}
