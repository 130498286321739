// @ts-check

import ertragswohnung from "./ertragswohnung";

/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const sonstigeVermietobjekte = {
  ...ertragswohnung,
  name: `Sonstige Vermietobjekte`,
  tagline:
    "Hierbei investieren Sie in sonstige selbstständige Räumlichkeiten & KFZ-Abstellplätze, Gewerbeimmobilien, Logistikzentren etc.",
  facts: [
    "Mindestinvestment: ab € 60.000",
    "Veranlagungszeitraum: langfristig - über 20 Jahre",
    "Rendite: lfd. 3,5 – 8 % p.a. + Wertsteigerung",
  ],
  image:
    "https://www.immoselection.at/fileadmin/_processed_/6/c/csm_sonstige_objekte_cb0a10c6a1.jpg",
  url: "https://www.immoselection.at/investitionsarten/sonstige-vermietobjekte/",
};

export default sonstigeVermietobjekte;
