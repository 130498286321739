/** @jsxImportSource theme-ui */
// @ts-check
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "theme-ui";
import Index from "./routes";
import Standalone from "./routes/standalone";
import theme from "./theme";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/"  element={<Index/>} />
          <Route path="/standalone"  element={<Standalone/>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
