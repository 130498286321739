import { useLocation } from "react-router-dom";
import getQueryString from "../getQueryString";
import useAnswers from "./useAnswers";

/**
 *
 * @returns
 */
export default function useLink() {
  const answers = useAnswers();
  const location = useLocation();
  return (
    /**
     * @type {Partial<import("./useAnswers").Answers> | undefined} Changed answers.
     */
    changedAnswers
  ) =>
    `${location.pathname}?${getQueryString({
      ...answers,
      ...changedAnswers,
    })}`;
}
