// @ts-check
import immobilienfonds from "./immobilienfonds";
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const immobilienaktien = {
  ...immobilienfonds,
  name: "Immobilienaktien",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/f/0/csm_immoaktien_08dd10a765.jpg",
  url: "https://www.immoselection.at/investitionsarten/immobilienaktien/",
  tagline:
    "Sie erwerben Unternehmensanteile einer Aktiengesellschaft, die Immobilien entwickelt oder besitzt und verwaltet",
  facts: [
    "Mindestinvestment: ab € 500",
    "Veranlagungszeitraum: mittelfristig - mind. 10 Jahre (Empfehlung)",
    "Rendite: 0 – 10% p.a.",
  ],

  // Rules are inherited from immobilienfonds.
  rules: [
    // 1. Investionsziel
    {
      wenn: { laufendeEinnahmen: true },
      dann: {
        rang: "bedingt",
        grund:
          "Die jährlichen Ausschüttungen hängen am Unternehmenserfolg und können auch längerfristig ausfallen.",
      },
    },
    {
      wenn: { pensionsvorsorge: true },
      dann: {
        rang: "bedingt",
        grund:
          "die jährlichen Ausschüttungen hängen am Unternehmenserfolg und können auch längerfristig ausfallen.",
      },
    },
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: { kapitalsicherung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die Wertentwicklung der Aktien korreliert nicht zwingend mit der Wertentwicklung des Immobilienmarkts, wodurch das Investment nicht für die Kaptailsicherung geeignet ist.",
      },
    },
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund: "Eine Eigennutzung durch den Aktieninhaber ist nicht möglich.",
      },
    },
    {
      wenn: { spaetereEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund: "Eine Eigennutzung durch den Aktieninhaber ist nicht möglich.",
      },
    },

    // 3. Finanzierung
    {
      wenn: { finanzierung: true },
      dann: {
        rang: "bedingt",
        grund:
          "Die Hebelmöglichkeit ist abhängig von der Bewertung der Veranlagung als Sicherheit durch die Bank.",
      },
    },

    // 4. Laufenden Eigenmittel
    {
      wenn: {
        eigenmittelersatz: false,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die gewünschte Hebelung werden laufende Eigenmittel für die Finanzierungsrückführung benötigt. Eine endfällige Finanzierungsgestaltung, wodurch keine laufenden Zuschüsse nötig wären, wird nicht empfohlen.",
      },
    },
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Eigenmittelfinanzierung und die gewünschte Hebelung ist jedenfalls laufend Kapital zuzuführen um die Finanzierung zu tilgen. Eine endfällige Finanzierungsgestaltung, wodurch keine laufenden Zuschüsse nötig wären, wird nicht empfohlen.",
      },
    },
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: false,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Eigenmittelfinanzierung ist laufender Kapitaleinsatz für die Finanzierungsrückführung notwendig. Eine endfällige Finanzierungsgestaltung, wodurch keine laufenden Zuschüsse nötig wären, wird nicht empfohlen.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: { kapitalbindung: "-5Jahre" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die gewünschte Kapitalbindungszeit ist für diese Veranlagungsform zu gering.",
      },
    },
  ],
};

export default immobilienaktien;
