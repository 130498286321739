// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const immobilienfonds = {
  name: "Immobilienfonds",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/a/1/csm_immofonds_fff11dab47.jpg",
  url: "https://www.immoselection.at/investitionsarten/immobilienfonds/",
  tagline:
    "Hierbei wird das Kapital unterschiedlicher Anleger gebündelt und in Folge in Immobilien bzw. Immobilienbeteiligungen investiert.",
  facts: [
    "Mindestinvestment: einmalig € 500 /monatlich ab € 50",
    "Veranlagungszeitraum: kurzfristig - über 5 Jahre (Empfehlung)",
    "Rendite: 0 – 10% p.a.",
  ],
  rules: [
    // 1. Investionsziel
    {
      wenn: { laufendeEinnahmen: true },
      dann: {
        rang: "bedingt",
        grund:
          "Laufende Einnahmen erhält der Investor nur mit ausschüttenden Fonds.",
      },
    },
    {
      wenn: { pensionsvorsorge: true },
      dann: {
        rang: "bedingt",
        grund: "Abhängig von dem ausgewählten Fonds.",
      },
    },
    {
      wenn: { steuerlicheAbschreibung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: { kapitalsicherung: true },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die Wertentwicklung des Fonds korreliert nicht zwingend mit der Wertentwicklung des Immobilienmarkts, wodurch das Investment nicht für die Kaptailsicherung geeignet ist.",
      },
    },
    {
      wenn: { sofortigeEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund: "Eine Eigennutzung durch den Fondsinhaber ist nicht möglich.",
      },
    },
    {
      wenn: { spaetereEigennutzung: true },
      dann: {
        rang: "ungeeignet",
        grund: "Eine Eigennutzung durch den Fondsinhaber ist nicht möglich.",
      },
    },

    // 3. Finanzierung
    {
      wenn: { finanzierung: true },
      dann: {
        rang: "bedingt",
        grund:
          "Die Hebelmöglichkeit ist abhängig von der Bewertung der Veranlagung als Sicherheit durch die Bank.",
      },
    },

    // 4. Laufenden Eigenmittel
    {
      wenn: {
        eigenmittelersatz: false,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die gewünschte Hebelung werden laufende Eigenmittel für die Finanzierungsrückführung benötigt. Eine endfällige Finanzierungsgestaltung, wodurch keine laufenden Zuschüsse nötig wären, wird nicht empfohlen.",
      },
    },
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Eigenmittelfinanzierung und die gewünschte Hebelung ist jedenfalls laufend Kapital zuzuführen um die Finanzierung zu tilgen. Eine endfällige Finanzierungsgestaltung, wodurch keine laufenden Zuschüsse nötig wären, wird nicht empfohlen.",
      },
    },
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: false,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Eigenmittelfinanzierung ist laufender Kapitaleinsatz für die Finanzierungsrückführung notwendig. Eine endfällige Finanzierungsgestaltung, wodurch keine laufenden Zuschüsse nötig wären, wird nicht empfohlen.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: { kapitalbindung: "-5Jahre" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Die gewünschte Kapitalbindungszeit ist für diese Veranlagungsform zu gering.",
      },
    },
  ],
};

export default immobilienfonds;
