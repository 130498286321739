export default function parseAnswers(values) {
  return Object.keys(values).reduce((answers, question) => {
    return { ...answers, [question]: parseAnswer(values[question]) };
  }, {});
}

function parseAnswer(answer) {
  if (answer === "true") return true;
  if (answer === "false") return false;
  return answer;
}
