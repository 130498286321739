// @ts-check

import zinshausanteilGrundbuch from "./zinshausanteilGrundbuch";

/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const immopartsMEG = {
  ...zinshausanteilGrundbuch,
  name: "Immoparts MEG",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/2/c/csm_immoparts_meg_433add5d56.jpg",
  url: "https://www.immoselection.at/investitionsarten/immoparts-meg/",
  tagline:
    "Bei diesem kleinteiligen Immobilien-Direktinvestment erwerben Sie anteiliges, im Grundbuch eingetragenes, Eigentum an einem Zinshaus",
  facts: [
    "Mindestinvestment: ab € 25.000",
    "Veranlagungszeitraum: mittelfristig - über 10 Jahre",
    "Rendite: lfd. 2 – 5% p.a. + Wertsteigerung",
  ],
};

export default immopartsMEG;
