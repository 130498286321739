// @ts-check
/**
 * @type {import("../rankProducts").ProductDefinition}
 */
const baurechtswohnungseigentum = {
  name: "Baurechtswohnungseigentum",
  image:
    "https://www.immoselection.at/fileadmin/_processed_/d/b/csm_baurechtsweg_26db0e68c7.jpg",
  url:
    "https://www.immoselection.at/investitionsarten/baurechtswohnungseigentum/",
  tagline:
    "Hierbei kommt es zu einer Trennung zwischen Grundstücks- und Wohnungseigentum",
  facts: [
    "Mindestinvestment: ab € 120.000",
    "Veranlagungszeitraum: langfristig - über 20 Jahre",
    "Rendite: lfd. 2,5 - 4,5% p.a.",
  ],
  rules: [
    // 1. Investitionsziel
    {
      wenn: {
        steuerlicheAbschreibung: true,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Durch die Investition wird keine Senkung der Einkommenssteuerbasis erreicht.",
      },
    },
    {
      wenn: {
        sofortigeEigennutzung: true,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Eine sofortige Eigennutzung einer Vorsorgewohnung führt zum Verlust des bei Kauf gewährten Umsatzsteuervorteils.",
      },
    },

    // 3. Finanzierung
    {
      wenn: {
        eigenmittel: "0-50k",
        finanzierung: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Ohne einer zusätzlichen Finanzierung reichen die Eigenmittel bzw. der Eigenmittelersatz nicht für den Ankauf eines Baurechtswohnungseigentums.",
      },
    },

    // 4. Laufende Eigenmittel
    {
      wenn: {
        eigenmittelersatz: false,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für den Baurechtszins und die Finanzierungsrückführung zur Verfügung stehen.",
      },
    },
    {
      wenn: {
        eigenmittelersatz: true,
        finanzierung: true,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "ungeeignet",
        grund:
          "Da die Eigenmittel finanziert sind und die Investition mittels Finanzierung erhöht wurde, decken die Mieteinnahmen nicht den laufenden Kapitalbedarf.",
      },
    },
    {
      wenn: {
        finanzierung: false,
        laufendeEigenmittel: false,
      },
      dann: {
        rang: "bedingt",
        grund:
          "Bei etwaigen Mietausfällen muss laufend Kapital für den Baurechtszins zur Verfügung stehen.",
      },
    },

    // 5. Kapitalbindung
    {
      wenn: { kapitalbindung: "-5Jahre" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Auf Grund der kurzen gewünschten Kapitalbindung ist es fraglich, ob die Kaufnebenkosten sowie die Nachverrechnung der Umsatzsteuer mit der Wertsteigerung bereits verdient wurde.",
      },
    },
    {
      wenn: { kapitalbindung: "6-10Jahre" },
      dann: {
        rang: "ungeeignet",
        grund:
          "Auf Grund der kurzen gewünschten Kapitalbindung ist es fraglich, ob die Kaufnebenkosten sowie die Nachverrechnung der Umsatzsteuer mit der Wertsteigerung bereits verdient wurde.",
      },
    },
    {
      wenn: { kapitalbindung: "11-25Jahre" },
      dann: {
        rang: "bedingt",
        grund:
          "Der Verkauf führt mindestens noch zu einer Nachversteuerung der Umsatzsteuer.",
      },
    },
  ],
};

export default baurechtswohnungseigentum;
