/** @jsxImportSource theme-ui */
// @ts-check
import { Box, Heading } from "theme-ui";
import { getVeranlagungszeitraum } from "../../getSummary";
import LinkedRadio from "../LinkedRadio";
import useLink from "../useAnswerLink";
import useAnswers from "../useAnswers";
import CollapsedSection from "./CollapsedSection";
import ExpandedSection from "./ExpandedSection";
import InfoBox from "./InfoBox";

export default function Veranlagungszeitraum({ ...rest }) {
  const answers = useAnswers();
  const getLink = useLink();
  if (answers.section !== "veranlagungszeitraum") {
    return (
      <CollapsedSection to={getLink({ section: "veranlagungszeitraum" })}>
        <CollapsedSection.Title>Veranlagungszeitraum:</CollapsedSection.Title>{" "}
        {getVeranlagungszeitraum(answers)}
      </CollapsedSection>
    );
  }

  return (
    <ExpandedSection
      previousLink={getLink({ section: "laufendeMittel" })}
      nextLink={getLink({ section: "ergebnis" })}
    >
      <Box {...rest}>
        <InfoBox
          info="Zeitpunkt, ab dem Sie das veranlagte Kapital wieder benötigen (z.B. Hausbau in X Jahren)"
          sx={{ marginY: 6 }}
        >
          <Heading as="h3">Veranlagungszeitraum</Heading>
        </InfoBox>
        <LinkedRadio name="kapitalbindung" value="-5Jahre">
          Bis zu 5 Jahre
        </LinkedRadio>
        <LinkedRadio name="kapitalbindung" value="6-10Jahre">
          6–10 Jahre
        </LinkedRadio>
        <LinkedRadio name="kapitalbindung" value="11-25Jahre">
          11–25 Jahre
        </LinkedRadio>
        <LinkedRadio name="kapitalbindung" value="25Jahre-">
          Über 25 Jahre
        </LinkedRadio>
      </Box>
    </ExpandedSection>
  );
}
