/**
 * Creates a URL to open the results page.
 * @param {import("./ProductSearchPage/useAnswers").Answers} answers
 * @param {string[]} [blacklist]
 * @returns
 */
export default function getQueryString(answers, blacklist = []) {
  const queryString = Object.entries(answers)
    .filter(([key, answer]) => answer !== undefined && !blacklist.includes(key))
    .map(
      ([key, answer]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(answer)}`
    )
    .join("&");
  return queryString;
}
