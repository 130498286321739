/** @jsxImportSource theme-ui */
// @ts-check
import { Link } from "react-router-dom";
import { Box, Checkbox, Text } from "theme-ui";
import useAnswerLink from "./useAnswerLink";
import useAnswers from "./useAnswers";

export default function LinkedCheckbox({ name, children, ...rest }) {
  const answers = useAnswers();
  const isChecked = Boolean(answers[name]);
  const getLink = useAnswerLink();
  return (
    <Link
      to={getLink({ [name]: !isChecked })}
      replace
      sx={{ textDecoration: "none", color: "inherit" }}
    >
      <Box
        as="label"
        sx={{ marginBottom: 4, display: "flex", alignItems: "flex-start" }}
        {...rest}
      >
        <Checkbox name={name} checked={isChecked} value="true" readOnly />{" "}
        <Text sx={{ display: "inline-block", maxWidth: "paragraph" }}>
          {children}
        </Text>
      </Box>
    </Link>
  );
}
