/** @jsxImportSource theme-ui */
// @ts-check
import React from "react";
import { Flex, Grid } from "theme-ui";
import groupResults from "../../groupResults";
import rankProducts from "../../rankProducts";
import parseAnswers from "../parseAnswers";
import PageLayout from "../theme/PageLayout";
import PageTitle from "../theme/PageTitle";
import Subtitle from "../theme/Subtitle";
import Form from "./Form";
import ProductFilters from "./ProductFilters";
import ProductList from "./ProductList";
import SendButton from "./SendButton";
import useAnswers from "./useAnswers";

export default function ProductSearchPage({ products, ...rest }) {
  return (
    <PageLayout {...rest}>
      <PageTitle>Finden Sie die passende Immobilieninvestition</PageTitle>
      <Explainer />
      <Form>
        <Flex
          sx={{ flexDirection: ["column", "row"], alignItems: "flex-start" }}
        >
          <ProductFilters
            products={products}
            sx={{ marginRight: 3, marginBottom: 6, flex: [null, "0 0 280px"] }}
          />
          <Results products={products} sx={{ flex: "1 1 auto" }} />
        </Flex>
        <SendButton
          sx={{ display: ["block", "none"], marginTop: 12, marginBottom: 12 }}
        />
      </Form>
    </PageLayout>
  );
}

function Explainer() {
  return (
    <>
      <Subtitle>So einfach geht's:</Subtitle>
      <ol>
        <li>Investionskriterien auswählen</li>
        <li>Ergebnisse ansehen</li>
        <li>Angebot/Kalkulation anfordern</li>
        <li>Zielinvestment finden</li>
      </ol>

      <p>
        <a
          href="https://www.immoselection.at/fragebogen/#c1359"
          target="_top"
          sx={{
            color: "primary",
            textDecoration: "none",
            ":hover": { textDecoration: "underline" },
          }}
        >
          Mehr erfahren
        </a>
      </p>
    </>
  );
}

function Results({ products, ...rest }) {
  const answers = useAnswers();
  const byRank = useGroupedResults({
    products,
    answers,
  });

  return (
    <Grid sx={{ gap: 8 }} {...rest}>
      <ProductList
        title={
          <Plural
            value={byRank.gut?.length}
            one="# geeignete Investitionsart"
            other="# geeignete Investitionsarten"
          />
        }
        products={byRank.gut}
      />

      <ProductList
        title={
          <Plural
            value={byRank.bedingt?.length}
            one="# weniger geeignete Investitionsart"
            other="# weniger geeignete Investitionsarten"
          />
        }
        products={byRank.bedingt}
      />
      <ProductList
        title={
          <Plural
            value={byRank.schlecht?.length}
            one="# schlecht geeignete Investitionsart"
            other="# schlecht geeignete Investitionsarten"
          />
        }
        products={byRank.schlecht}
      />
      <ProductList
        title={
          <Plural
            value={byRank.ungeeignet?.length}
            one="# nicht geeignete Investitionsart"
            other="# nicht geeignete Investitionsarten"
          />
        }
        products={byRank.ungeeignet}
      />
    </Grid>
  );
}

function Plural({ value, one, other }) {
  // Simulate lingui Plural
  if (value === 1) return one?.replace("#", value);
  return other?.replace("#", value);
}

/**
 *
 * @param {object} props
 * @param {import("../../rankProducts").RankedProduct[]} props.products
 * @param {import("../../rankProducts").Answers} props.answers
 * @returns
 */
function useGroupedResults({ products, answers }) {
  const rankedProducts = React.useMemo(() => {
    return rankProducts(parseAnswers(answers), products);
  }, [answers, products]);

  const byRank = React.useMemo(() => {
    console.log("calculating grouped");
    return groupResults(rankedProducts);
  }, [rankedProducts]);
  return byRank;
}
