/** @jsxImportSource theme-ui */
// @ts-check
import { Link } from "react-router-dom";
import { Box } from "theme-ui";

/**
 *
 * @param {object} props
 * @param {import('../../theme').ButtonVariant} props.variant
 * @param {React.ReactNode} props.children
 * @param {(event: object) => void} [props.onClick]
 * @param {string} [props.href]
 * @param {string} [props.to]
 * @param {'button' | 'submit'} [props.type]
 * @param {boolean} [props.disabled]
 * @param {string} [props.target]
 * @param {string} [props.title]
 * @param {'noreferrer'} [props.rel]
 * @param {import("theme-ui").ThemeUICSSObject} [props.sx]
 * @returns
 */
export default function Button({
  variant,
  children,
  to,
  href,
  onClick,
  disabled,
  type,
  ...rest
}) {
  return (
    <Box
      as={to ? Link : href ? "a" : "button"}
      // @ts-ignore type is needed for button for forms.
      type={href ? undefined : type || "button"}
      href={href}
      to={to}
      // @ts-ignore onClick is supported on both a and button.
      onClick={onClick}
      disabled={disabled}
      sx={{
        // Normalizing across button and link:
        fontFamily: "text",
        fontWeight: "text",
        fontSize: 2,
        display: "inline-block",
        textDecoration: "none",
        // Base styles:
        paddingX: 4,
        paddingY: 2,
        opacity: disabled ? 0.7 : 1,
        variant: `buttons.${variant}`,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
