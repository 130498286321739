const theme = {
  colors: {
    background: "#fff",
    text: "black",
    good: { color: "#6FBFA2", contrast: "#fff" },
    bedingt: { color: "#eace1a", contrast: "#000" },
    neutral: { color: "#F28705", contrast: "#fff" },
    bad: { color: "#D95252", contrast: "#fff" },
    negative: { background: "#D95252", contrast: "#fff" },
    border: "#999",
    primary: "#3C618B",
    onPrimary: "#fff",
    primaryOverlay: "rgba(16, 35, 47, 0.7)",
    secondary: "rgba(16, 35, 47, .95)",
    onSecondary: "#fff",
    accent: "#F28705",
    onAccent: "#fff",
    highlight: "#ddd",
    header: "rgba(16,35,47,.95)",
  },
  borders: { 0: "none", 1: "1px solid", 2: "2px solid" },
  radii: { none: 0, default: 3, large: 4, round: 9999 },
  config: { useBorderBox: false },
  space: {
    0: 0,
    px: 1,
    0.5: 2,
    1: 4,
    1.5: 6,
    2: 8,
    3: 12,
    4: 16,
    6: 24,
    8: 32,
    12: 48,
    16: 64,
  },
  sizes: { paragraph: "65ch", frame: 1100, container: 1140 },

  fonts: {
    heading: '"Oswald", sans-serif',
    text: '"Source Sans Pro", sans-serif',
  },

  fontWeights: {
    heading: 400,
    text: 300,
  },

  buttons: {
    primary: {
      color: "onPrimary",
      backgroundColor: "primary",
      border: 1,
      borderColor: "primary",
      borderRadius: "default",
    },
    secondary: {
      variant: "buttons.primary",
      color: "primary",
      backgroundColor: "transparent",
      borderColor: "muted",
    },
    accent: {
      variant: "buttons.primary",
      color: "onAccent",
      backgroundColor: "accent",
      borderColor: "accent",
    },
    link: {
      variant: "buttons.secondary",
      color: "primary",
      border: 0,
      paddingX: 0,
      ":hover": { color: "secondary" },
    },
    text: {
      variant: "buttons.link",
      color: "text",
      outline: "none",
      padding: 0,
      textAlign: "left",
    },
    icon: {
      border: 0,
      paddingX: 1,
      paddingY: 0,
      backgroundColor: "transparent",
      color: "inherit",
    },
    productName: {
      border: 0,
      paddingX: 1,
      paddingY: 0,
      backgroundColor: "transparent",
      color: "inherit",
      fontFamily: "text",
      fontWeight: "text",
      fontSize: 2,
      textAlign: "center",
      display: "block",
      width: "100%",
      textTransform: "uppercase",
    },
    // This is a "continue" button directly in the heading. It has a bottom border only.
    title: {
      color: "text",
      border: 0,
      borderRadius: 0,
      borderBottom: 1,
      borderColor: "border",
    },
  },

  links: {
    text: { textDecoration: "none", color: "inherit" },
  },

  text: {
    default: { fontFamily: "text", fontWeight: "text" },
    small: {
      variant: "text.default",
      fontSize: 1,
    },
  },

  heading: {
    pageTitle: {
      fontFamily: "heading",
      fontWeight: "heading",
      fontSize: 6,
      margin: 0,
      textTransform: "uppercase",
    },
    pageSubtitle: {
      fontFamily: "heading",
      fontWeight: "heading",
      fontSize: 5,
      margin: 0,
    },
    header: {
      fontFamily: "text",
      fontWeight: "heading",
      fontSize: 3,
      textTransform: "uppercase",
      margin: 0,
    },
  },

  product: {
    gut: { borderColor: "good.color" },
    neutral: { borderColor: "white" },
    bedingt: { borderColor: "bedingt.color" },
    schlecht: { borderColor: "neutral.color" },
    ungeeignet: { borderColor: "bad.color" },
    details: {
      default: { transition: "opacity 0.3s" },
      gut: {
        backgroundColor: "good.color",
        color: "good.contrast",
        variant: "product.details.default",
        ":hover": { color: "good.contrast", opacity: 0.8 },
      },
      neutral: {
        backgroundColor: "white",
        color: "white.contrast",
        variant: "product.details.default",
        ":hover": { color: "white.contrast", opacity: 0.8 },
      },
      bedingt: {
        backgroundColor: "bedingt.color",
        color: "bedingt.contrast",
        variant: "product.details.default",
        ":hover": { color: "bedingt.contrast", opacity: 0.8 },
      },
      schlecht: {
        backgroundColor: "neutral.color",
        color: "neutral.contrast",
        variant: "product.details.default",
        ":hover": { color: "neutral.contrast", opacity: 0.8 },
      },
      ungeeignet: {
        backgroundColor: "bad.color",
        color: "bad.contrast",
        variant: "product.details.default",
        ":hover": { color: "bad.contrast", opacity: 0.8 },
      },
    },
  },

  productName: {
    gut: { borderColor: "good.color", outlineColor: "good.color" },
    neutral: { borderColor: "white", outlineColor: "white" },
    bedingt: { borderColor: "bedingt.color", outlineColor: "bedingt.color" },
    schlecht: { borderColor: "neutral.color", outlineColor: "neutral.color" },
    ungeeignet: { borderColor: "bad.color", outlineColor: "bad.color" },
  },

  bulletpoint: {
    gut: { color: "good.color", textShadow: "0 0 1px black" },
    bedingt: { color: "bedingt.color", textShadow: "0 0 1px black" },
    schlecht: { color: "neutral.color", textShadow: "0 0 1px black" },
    ungeeignet: { color: "bad.color", textShadow: "0 0 1px black" },
  },

  cardImage: {
    gut: {},
    bedingt: { filter: "saturate(0.5)", opacity: 0.9 },
    schlecht: { filter: "saturate(0.375)", opacity: 0.9 },
    ungeeignet: { filter: "saturate(0.25)", opacity: 0.9 },
  },
};

/** @type {import("theme-ui").Theme} */ // @ts-ignore
export default theme;

/** @typedef {keyof typeof theme.productName} ProductVariant */
/** @typedef {keyof typeof theme.buttons} ButtonVariant */
