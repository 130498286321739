/**
 * @param {import("../rankProducts").Answers} answers
 * @returns {string}
 */
export default function getSummary(answers) {
  return [
    `Investitionsziele: ${getZiele(answers)}`,
    `Eigenmittel: ${getEigenmittel(answers)}`,
    `Hebelung mittels Finanzierung: ${getHebelung(answers)}`,
    `Laufende Mittel: ${getLaufendeMittel(answers)}`,
    `Veranlagungszeitraum: ${getVeranlagungszeitraum(answers)}`,
  ].join("\n");
}

export function getZiele(answers) {
  return (
    [
      answers.vermoegensaufbau && "Vermögensaufbau",
      answers.pensionsvorsorge && "Pensionsvorsorge",
      answers.laufendeEinnahmen && "Laufende Einnahmen",
      answers.steuerlicheAbschreibung && "Senkung der Einkommensteuer",
      answers.kapitalsicherung && "Kapitalsicherung",
      answers.sofortigeEigennutzung && "Eigennutzung sofort",
      answers.spaetereEigennutzung && "Eigennutzung später",
    ]
      .filter(Boolean)
      .join(", ") || "bitte auswählen"
  );
}

export function getEigenmittel(answers) {
  return `${
    eigenmittel[answers.eigenmittel || "default"] || "bitte auswählen"
  }${when(answers.eigenmittelersatz, " (Sachwerte)", " (Eigenmittel)", "")}`;
}

export function getHebelung(answers) {
  return when(answers.finanzierung, "ja", "nein");
}

export function getLaufendeMittel(answers) {
  return when(answers.laufendeEigenmittel, "ja", "nein");
}

export function getVeranlagungszeitraum(answers) {
  return (
    kapitalbindung[answers.kapitalbindung || "default"] || "bitte auswählen"
  );
}

function when(value, ifTrue, ifFalse, ifNull = "bitte auswählen") {
  if (value === true || value === "true") return ifTrue;
  if (value === false || value === "false") return ifFalse;
  return ifNull;
}

/** @type {Record<import("../rankProducts").Eigenmittel, string>} */
export const eigenmittel = {
  "0-50k": "bis € 50.000",
  "50k-250k": "€ 50.001 – € 250.000",
  "250k-500k": "€ 250.001 – € 500.000",
  "500k-": "Ab € 500.000",
};

/** @type {Record<import("../rankProducts").KapitalbindungValues, string>} */
export const kapitalbindung = {
  "-5Jahre": "bis zu 5 Jahre",
  "6-10Jahre": "6–10 Jahre",
  "11-25Jahre": "11–25 Jahre",
  "25Jahre-": "über 25 Jahre",
};
