/** @jsxImportSource theme-ui */
// @ts-check
import { FiEdit2 } from "react-icons/fi";
import { Box, Text } from "theme-ui";
import Button from "../../theme/Button";

export default function CollapsedSection({ children, to, ...rest }) {
  return (
    <Button
      variant="text"
      to={to}
      sx={{ display: "flex", width: "100%", cursor: "pointer" }}
      {...rest}
    >
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
      <Box sx={{ color: "border", paddingX: 2 }} title="Bearbeiten">
        <FiEdit2 />
      </Box>
    </Button>
  );
}
CollapsedSection.Title = CollapsedSectionTitle;

export function CollapsedSectionTitle({ children, ...rest }) {
  return (
    <Text as="span" sx={{ fontWeight: "bold" }} {...rest}>
      {children}
    </Text>
  );
}
