import React from "react";

export type Answers = import("../../rankProducts").Answers & {
  section?: Section;
};

export type Section =
  | "ziele"
  | "eigenmittel"
  | "hebelung"
  | "laufendeMittel"
  | "veranlagungszeitraum"
  | "ergebnis";

const AnswersContext = React.createContext<Answers>({});

export const AnswersProvider = AnswersContext.Provider;

export default function useAnswers() {
  const answers = React.useContext(AnswersContext);
  return answers;
}
