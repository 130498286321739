/** @jsxImportSource theme-ui */

export default function PageTitle({ children, ...rest }) {
  return (
    <h1
      sx={{
        fontFamily: "heading",
        fontWeight: "heading",
        textTransform: "uppercase",
      }}
      {...rest}
    >
      {children}
    </h1>
  );
}
