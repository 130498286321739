/** @jsxImportSource theme-ui */
// @ts-check
import { Box, Flex } from "theme-ui";
import Button from "../../theme/Button";

/**
 *
 * @param {object} props
 * @param {string} [props.previousLink]
 * @param {string} [props.nextLink]
 * @param {React.ReactNode} [props.children]
 * @returns
 */
export default function ExpandedSection({ previousLink, nextLink, children }) {
  return (
    <Box sx={{ paddingBottom: 4 }}>
      {children}
      <Flex sx={{ marginTop: 3 }}>
        {previousLink && (
          <Button variant="secondary" to={previousLink}>
            Zurück
          </Button>
        )}
        {nextLink && (
          <Button variant="primary" to={nextLink} sx={{ marginLeft: "auto" }}>
            Weiter
          </Button>
        )}
      </Flex>
    </Box>
  );
}
