/**
 * @param {import("./rankProducts").RankedProduct[]} rankedProducts
 * @returns {Record<import("./rankProducts").RankValue,import("./rankProducts").RankedProduct[]>}
 */
export default function groupResults(rankedProducts) {
  return rankedProducts.reduce(
    (byRank, product) => {
      const finalProduct = { lowestRanking: { rang: "gut" }, ...product };
      byRank[finalProduct.lowestRanking.rang].push(finalProduct);
      return byRank;
    },
    { gut: [], bedingt: [], schlecht: [], ungeeignet: [], unranked: [] }
  );
}
